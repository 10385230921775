import type { FC } from 'react'
import { classNames, getInitials } from '../utils'

export interface AvatarReplacerProps {
  className?: string
  name: string
}

const AvatarReplacer: FC<AvatarReplacerProps> = ({ className, name }) => {
  return (
    <div
      className={classNames(
        className ?? '',
        'inline-flex items-center justify-center rounded-full border border-slate-200 bg-slate-100 p-2 font-semibold',
      )}
    >
      {getInitials(name)}
    </div>
  )
}

export default AvatarReplacer