import type { FC } from 'react'

import { classNames } from '../utils/classNames'

export interface DropdownItemProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string
}

export const DropdownItem: FC<DropdownItemProps> = ({
  className,
  children,
  ...other
}) => {
  return (
    <button
      className={classNames(
        className ?? '',
        'flex w-full items-center text-left space-x-2.5 rounded-md py-1.5  px-2.5 font-medium hover:bg-blue-50',
      )}
      type="button"
      {...other}
    >
      {children}
    </button>
  )
}

export default DropdownItem
