import type { FC } from 'react'

import Head from 'next/head'

export interface Meta {
  title: string
  ogUrl?: string
  ogImage?: string
}

export const Seo: FC<{ meta?: Meta }> = ({ meta }) => {
  const title = meta?.title ? `${meta.title} - Teero` : 'Teero'
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:site_name" content="Teero" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={meta?.ogUrl} />
      <meta name="image" property="og:image" content={meta?.ogImage} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="google" content="notranslate" />
    </Head>
  )
}
