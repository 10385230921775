import type { FC } from 'react'

import { classNames } from '../utils/classNames'

export const BadgeVariantArray = [
  'info',
  'success',
  'error',
  'blue-outline',
  'coming-soon',
  'warning',
  'ghost',
] as const

export type BadgeVariant = typeof BadgeVariantArray[number]

export type BadgeSize = 'md' | 'xl'

export interface BadgeProps {
  className?: string
  variant: BadgeVariant
  size?: BadgeSize
}

const variantToStyle = (variant: BadgeVariant) => {
  if (variant === 'info') {
    return 'bg-slate-100 text-slate-700 font-normal'
  }
  if (variant === 'success') {
    return 'bg-emerald-100 text-emerald-900 font-medium'
  }
  if (variant === 'error') {
    return 'bg-red-100 text-red-900 font-medium'
  }
  if (variant === 'blue-outline') {
    return 'text-blue-600 border border-blue-600 font-medium'
  }
  if (variant === 'coming-soon') {
    return 'bg-white border border-slate-300 text-slate-500 font-medium text-xs'
  }
  if (variant === 'warning') {
    return 'bg-yellow-50 text-yellow-900 font-medium'
  }
  if (variant === 'ghost') {
    return 'border-slate-200 border'
  }
  return ''
}

export const Badge: FC<BadgeProps> = ({
  className,
  variant,
  size = 'xl',
  children,
}) => {
  return (
    <span
      className={classNames(
        variantToStyle(variant),
        'inline-flex items-center rounded-full text-sm leading-4',
        size === 'xl' ? 'px-2.5 py-1 text-sm' : 'px-1.5 py-0.5 text-xs',
        className ?? '',
      )}
    >
      {children}
    </span>
  )
}

export default Badge
