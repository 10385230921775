import type { FC } from 'react'

import { classNames } from '../utils/classNames'

type TeeroVariantType = 'default' | 'teal' | 'slate' | 'darkslate'

export interface TeeroLogoProps {
  className?: string
  variant?: TeeroVariantType
}

const getColor = (variant: TeeroVariantType) => {
  switch (variant) {
    case 'teal':
      return 'fill-teal-500'
    case 'slate':
      return 'fill-slate-600'
    case 'darkslate':
      return 'fill-slate-900'
    default:
      return 'fill-blue-600'
  }
}

export const TeeroLogo: FC<TeeroLogoProps> = ({
  className,
  variant = 'default',
}) => {
  return (
    <svg
      width="61"
      height="16"
      viewBox="0 0 61 16"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className ?? '', getColor(variant))}
    >
      <path d="M4.272 5.275v6.996c0 .475.115.815.345 1.022.228.207.621.31 1.175.31h1.662v2.179H5.318c-1.219 0-2.154-.274-2.801-.827-.648-.55-.974-1.445-.974-2.684V5.275H0V3.144h1.543V0h2.73v3.144h3.18v2.131h-3.18ZM53.835 2.544c-3.838 0-6.962 3.018-6.962 6.727 0 1.62.631 3.198 1.777 4.445l.082.089.083-.089a6.702 6.702 0 0 1 1.944-1.458l.13-.065-.102-.102a3.992 3.992 0 0 1-1.18-2.82c0-2.254 1.898-4.087 4.23-4.087 2.333 0 4.231 1.834 4.231 4.087 0 1.05-.42 2.051-1.182 2.82l-.101.102.13.065a6.702 6.702 0 0 1 1.944 1.458l.082.089.082-.089C60.17 12.47 60.8 10.891 60.8 9.271c-.001-3.709-3.125-6.727-6.965-6.727Z" />
      <path d="M53.837 13.722a4.48 4.48 0 0 0-3.208 1.334l-.1.102.129.065c1.028.516 2.098.777 3.179.777 1.08 0 2.15-.261 3.179-.777l.13-.065-.102-.102a4.472 4.472 0 0 0-3.207-1.334ZM43.523 3.264c.657-.359 1.436-.539 2.339-.539v2.707h-.688c-1.06 0-1.864.259-2.409.78-.546.52-.819 1.42-.819 2.706v6.651H39.24V2.933h2.706v1.834a4.17 4.17 0 0 1 1.577-1.503ZM30.219 13.574c-1.013 0-1.867-.306-2.564-.918-.698-.61-1.085-1.42-1.164-2.43h9.994a7.89 7.89 0 0 0 .095-1.285c0-1.222-.27-2.304-.807-3.245a5.604 5.604 0 0 0-2.255-2.19c-.964-.52-2.064-.78-3.3-.78-1.281 0-2.417.268-3.406.804a5.653 5.653 0 0 0-2.303 2.282c-.546.986-.818 2.13-.818 3.428 0 1.3.28 2.443.843 3.43a5.902 5.902 0 0 0 2.326 2.293c.99.543 2.11.815 3.359.815 1.535 0 2.82-.37 3.857-1.114A5.734 5.734 0 0 0 36 12.335h-3.056c-.62.81-1.516 1.24-2.724 1.24Zm-2.516-7.788c.665-.573 1.465-.86 2.398-.86 1.029 0 1.89.292 2.588.873.697.58 1.052 1.352 1.068 2.316h-7.241c.127-.978.523-1.755 1.187-2.33ZM15.588 13.574c-1.013 0-1.867-.306-2.564-.918-.697-.61-1.085-1.42-1.164-2.43h9.994a7.89 7.89 0 0 0 .095-1.285c0-1.222-.27-2.304-.807-3.245a5.603 5.603 0 0 0-2.255-2.19c-.964-.52-2.064-.78-3.299-.78-1.282 0-2.418.268-3.407.804a5.654 5.654 0 0 0-2.303 2.282c-.545.986-.818 2.13-.818 3.428 0 1.3.28 2.443.843 3.43a5.902 5.902 0 0 0 2.326 2.293c.99.543 2.11.815 3.359.815 1.535 0 2.82-.37 3.857-1.114a5.735 5.735 0 0 0 1.923-2.329h-3.056c-.62.81-1.516 1.24-2.724 1.24Zm-2.517-7.788c.665-.573 1.465-.86 2.398-.86 1.028 0 1.89.292 2.587.873.697.58 1.053 1.352 1.069 2.316h-7.24c.125-.978.523-1.755 1.186-2.33Z" />{' '}
    </svg>
  )
}

export default TeeroLogo
